import React, { FC, useRef, useEffect, useState } from "react";
import Section from "../../components/section";
import { PageHero } from "../../components/page-hero";
import Layout from "../../components/layout/layout";
import tech_1 from "../../images/monitoringScreens/teck_1.jpg";
import tech_2 from "../../images/monitoringScreens/teck_2.jpg";
import tech_3 from "../../images/monitoringScreens/teck_3.jpg";
import tech_4 from "../../images/monitoringScreens/teck_4.jpg";
import tech_5 from "../../images/monitoringScreens/teck_5.jpg";

import useCalculateHeightHook from "../../hooks/useCalculateHeightHook";
import "./styles.scss";

const TecknologyMonitoring: FC = () => {
  const [size, videoLength] = useCalculateHeightHook();

  return (
    <div className="content-wrapper">
      <PageHero
        title="Technology monitoring"
        subtitle="Monitor changes to the tech stack or 3rd party scripts used on the web page."
      />
      <Layout>
        <Section>
          <div className="col-lg-12 technology-monitoring">
            <div className="go-back">
              <a className="learn_btn_two" href="/how-to-use-hexowatch">
                <svg
                  width="40px"
                  height="20px"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="long-arrow-alt-left"
                  className="svg-inline--fa fa-long-arrow-alt-left fa-w-14 icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"
                  ></path>
                </svg>
                Back to how to use Hexowatch
              </a>
            </div>

            {size ? (
              <video
                controls
                poster={tech_1}
                preload="none"
                ref={videoLength}
                style={{ height: size ? size : "auto" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Technology_monitor_-_Walkthrough_1598174701.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            ) : (
              <video
                controls
                poster={tech_1}
                preload="none"
                ref={videoLength}
                style={{ visibility: "hidden" }}
              >
                <source
                  src={
                    "https://storage.googleapis.com/website.hexometer.com/Technology_monitor_-_Walkthrough_1598174701.mp4"
                  }
                  type="video/mp4"
                />
              </video>
            )}

            <h2>Technology monitor - Walkthrough</h2>
            <br />
            <div className="img-block">
              <img src={tech_1} alt="Select technology monitoring tool" />
            </div>
            <p className="mb">
              With our technology monitor, you can check any page for tech
              changes and get alerts when third party scripts are added or
              removed from that page. This type of monitor is ideal to check if
              a tracking script was removed by mistake or to monitor a prospects
              website so you can be alerted if they make any change of vendor.
            </p>

            <div className="img-block">
              <img src={tech_2} alt="Preview desired URL" />
            </div>
            <p className="mb">
              To get started enter the URL you want to check and click preview.
            </p>

            <div className="img-block">
              <img
                src={tech_3}
                alt="Hexowatch will then detect the current tech stack used on the page"
              />
            </div>
            <p className="mb">
              Hexowatch will then detect the current tech stack used on the
              page. You can then choose to monitor for any tech changes, or
              specify a specific technology or script to look for. For example,
              you can use this to detect if Google analytics was removed by
              mistake.
            </p>

            <div className="img-block">
              <img
                src={tech_4}
                alt="You can choose the frequency of the checks"
              />
            </div>
            <p className="mb">
              Next, you can choose the frequency of the checks, your preferred
              notification channels, as well as set custom start and end dates.
            </p>

            <div className="img-block">
              <img src={tech_5} alt="Click start monitoring to get started" />
            </div>
            <p className="mb">
              Then click start monitoring to get started. Hexowatch will keep an
              eye on the page and will notify you when any technology changes
              are detected.
            </p>
          </div>
        </Section>
      </Layout>
    </div>
  );
};

export default TecknologyMonitoring;
